import { useState, useEffect } from 'react'

import { useProposal, useLead } from '@itau-loans-www/shopping/src/hooks'
import analytics from '@mobi/libraries/analytics'
import { goTo } from 'utils'

import services from '../services'

const usePropertyDataChoose = () => {
  const { setIsLoading } = useProposal()
  const { changeLeadData } = useLead()

  const [onLoadData, setOnLoadData] = useState({
    prevStep: ''
  })

  useEffect(() => {
    const getData = async () => {
      const { previous_step } = await services.getProperty()
      changeLeadData({
        previousStep: previous_step
      })
      setOnLoadData({
        prevStep: previous_step
      })

      setIsLoading(false)
    }

    getData()
    console.log('property-data mounted')
  }, [setIsLoading])

  useEffect(() => {
    analytics.track('pageLoad', { currentPage: 'proposta/dados-imovel' })
  }, [])

  const onSubmit = async (answer) => {
    if (answer === 'sim') {
      analytics.track('buttonClick', {
        currentPage: 'proposta/dados-imovel',
        detail: 'click-step-dados-imovel-sim'
      })
      changeLeadData({
        previousStep: 'property-data'
      })
      goTo('property-data-customize')
    } else {
      analytics.track('buttonClick', {
        currentPage: 'proposta/dados-imovel',
        detail: 'click-step-dados-imovel-nao'
      })
      setIsLoading(true)

      const requestBody = {
        has_property: false,
        property_value: 0
      }

      return services
        .putProperty(requestBody)
        .then(({ next_step }) => goTo(next_step))
        .catch(() => setIsLoading(false))
    }
  }

  return { onSubmit, onLoadData }
}

export default usePropertyDataChoose
